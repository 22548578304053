import request from '@/auth/jwt/request'

/**
 * @description 员工海报分类 -- 员工海报分类列表
 * @param {Object} param params {Object} 传值参数
 */
export const staffImageCateListApi = params => { return request('staff-poster-category/list', 'get', params) }

/**
 * @description 员工海报分类 -- 增加修改员工海报分类
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const staffImageCateSaveApi = data => { return request('staff-poster-category/save', 'post', {}, data) }

/**
 * @description 员工海报分类 -- 员工海报分类状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const staffImageCateStatusApi = params => { return request('staff-poster-category/status', 'get', params) }

/**
 * @description 员工海报分类 -- 员工海报分类详情
 * @param {Object} param params {Object} 传值参数
 */
export const staffImageCateInfoApi = params => { return request('staff-poster-category/info', 'get', params) }
/**
 * @description 员工海报分类 -- 删除员工海报分类
 * { id = x }
 */
export const staffImageCateDeleteApi = data => { return request('staff-poster-category/delete', 'delete', data) }